window._stack.push([
  function(di, DOM) {
    var page = di.getService("page");
    var snippetManager = di.getService("snippetManager");

    var dependentControlChange = function(evt) {
      var url = DOM.getData(evt.target, "dependent-control-link");
      var param = DOM.getData(evt.target, "dependent-control-param");
      var val = evt.target.value;

      var data = {};
      data[param] = val;

      page.open(url, "get", data, {
        element: evt.target,
        background: true
      });
    };

    var controlChangeSubmit = function(evt) {
      var formEl = DOM.closest(evt.target, null, "form");
      DOM.trigger(formEl, "submit");
    };

    var bindControlEvents = function(parentEl) {
      var controls = DOM.getByClassName("dependent-control", parentEl);
      controls.forEach(function(control) {
        DOM.addListener(control, "change", dependentControlChange);
      });

      var controls = DOM.getByClassName("control-change-submit", parentEl);
      controls.forEach(function(control) {
        DOM.addListener(control, "change", controlChangeSubmit);
      });
    };

    snippetManager.on("after-update", function(evt) {
      if ("update" in evt.data) {
        var updatedSnippets = evt.data.update;
        for (var s in updatedSnippets) {
          bindControlEvents(updatedSnippets[s].element);
        }
      }

      if ("add" in evt.data) {
        var addedSnippets = evt.data.add;
        for (var s in addedSnippets) {
          bindControlEvents(addedSnippets[s].element);
        }
      }
    });

    bindControlEvents(document);
  },
  {
    DOM: "Utils.DOM"
  }
]);

window._stack.push([
  function(di, DOM) {
    var snippetManager = di.getService("snippetManager");

    var bindControlEvents = function(parentEl) {
      var controls = DOM.getByClassName("spam-control", parentEl);
      controls.forEach(function(control) {
        control.value = DOM.getData(control, "spam");

        var container = DOM.closest(control, null, "form-group");
        DOM.addClass(container, "d-none");
      });
    };

    snippetManager.on("after-update", function(evt) {
      if ("update" in evt.data) {
        var updatedSnippets = evt.data.update;
        for (var s in updatedSnippets) {
          bindControlEvents(updatedSnippets[s].element);
        }
      }

      if ("add" in evt.data) {
        var addedSnippets = evt.data.add;
        for (var s in addedSnippets) {
          bindControlEvents(addedSnippets[s].element);
        }
      }
    });

    bindControlEvents(document);
  },
  {
    DOM: "Utils.DOM"
  }
]);

window._stack.push([
  function(di, DOM) {
    var snippetManager = di.getService("snippetManager");

    var applyStyles = function(parentEl) {
      var controls = DOM.getByClassName("ajax-hide", parentEl);
      controls.forEach(function(control) {
        DOM.addClass(control, "d-none");
      });
    };

    snippetManager.on("after-update", function(evt) {
      if ("update" in evt.data) {
        var updatedSnippets = evt.data.update;
        for (var s in updatedSnippets) {
          applyStyles(updatedSnippets[s].element);
        }
      }

      if ("add" in evt.data) {
        var addedSnippets = evt.data.add;
        for (var s in addedSnippets) {
          applyStyles(addedSnippets[s].element);
        }
      }
    });

    applyStyles(document);
  },
  {
    DOM: "Utils.DOM"
  }
]);
