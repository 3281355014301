window._stack.push([function (di, DOM) {
	var snippetManager = di.getService('snippetManager');
	
	
	var bindRecaptcha = function(parentEl) {
		var controls = DOM.getByClassName('g-recaptcha', parentEl);
		controls.forEach(function(control) {
			if (control.hasChildNodes()) {
				return;
			}

			grecaptcha.render(control, {});
		});		
	};

	snippetManager.on('after-update', function(evt) {
		if (('update' in evt.data)) {
			var updatedSnippets = evt.data.update;
			for (var s in updatedSnippets) {
				bindRecaptcha(updatedSnippets[s].element);
			};
		}

		if (('add' in evt.data)) {
			var addedSnippets = evt.data.add;
			for (var s in addedSnippets) {
				bindRecaptcha(addedSnippets[s].element);
			};
		}
	});

}, {
	DOM: 'Utils.DOM'
}]);