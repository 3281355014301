window._stack.push([
  function(di, DOM) {
    var snippetManager = di.getService("snippetManager");

    var applyClickEvent = function(parentEl) {
      var controls = DOM.getByClassName("anchor", parentEl);
      controls.forEach(function(control) {
        DOM.addListener(control, "click", function(evt) {
          evt.preventDefault();

          var link;
          if (evt.target.href) {
            link = evt.target;
          } else {
            link = DOM.closest(evt.target, null, "anchor");
          }

          if (!link) {
            return;
          }

          var anchor = link.href.split("#");
          if (anchor.length !== 2) {
            return;
          }

          var elem = DOM.getById(anchor[1]);
          if (!elem) {
            return;
          }

          window.scroll({
            top: elem.offsetTop - 100,
            left: 0,
            behavior: "smooth"
          });
        });
      });
    };

    snippetManager.on("after-update", function(evt) {
      if ("update" in evt.data) {
        var updatedSnippets = evt.data.update;
        for (var s in updatedSnippets) {
          applyClickEvent(updatedSnippets[s].element);
        }
      }

      if ("add" in evt.data) {
        var addedSnippets = evt.data.add;
        for (var s in addedSnippets) {
          applyClickEvent(addedSnippets[s].element);
        }
      }
    });

    applyClickEvent(document);
  },
  {
    DOM: "Utils.DOM"
  }
]);
