window._stack.push([
  function(di, DOM) {
    var snippetManager = di.getService("snippetManager");

    var applyBoostrap = function(parentEl) {
      $('[data-toggle="tooltip"]', parentEl).tooltip();
    };

    snippetManager.on("after-update", function(evt) {
      if ("update" in evt.data) {
        var updatedSnippets = evt.data.update;
        for (var s in updatedSnippets) {
          applyBoostrap(updatedSnippets[s].element);
        }
      }

      if ("add" in evt.data) {
        var addedSnippets = evt.data.add;
        for (var s in addedSnippets) {
          applyBoostrap(addedSnippets[s].element);
        }
      }
    });

    applyBoostrap(document);
  },
  {
    DOM: "Utils.DOM"
  }
]);
